import {Amount, MobileAppInformation, OnBoarding} from "./person";

export class AdminCorporateDetailsDto {
  corporate: CorporateDetailsDto;
  corporateOwner: AdminPersonDetails;
  staffDetails: AdminStaffDetail[];
  nbActiveProperties: number;
  nbInActiveProperties: number;
  potentialVoyageur: boolean;
  bookletUrls: string[];

}

export class CorporateDetailsDto {
  id: string;

   name: string;
  availableRoles: any[];
  address: string;
  zipCode: string;
  city: string;
  country: string;
  logoUrl: string;

  iglooHome: HostIglooHomeData;
  theKeys: HostTheKeysData;
  keynestAccount: KeynestAccount;
  nukiInformation: NukiInformation;
  minut: HostMinutData;
  swiklyLinked: boolean;
  swiklyAirbnbAllowed: boolean;
  stripeAccountId: string;
  individualCompany: boolean;
  stripeTaxId: string | null;
}
export class NukiInformation {
  nukiSmartLocks: any[];
}
export class HostIglooHomeData {
  locks: any[];
}
export class HostMinutData {
  devices: any[];
}
export class HostTheKeysData {
  locks: any[];
}
export class AdminPersonDetails {
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  phone: string;
  country: string;
  pictureUrl: string;
  id: string;
  beta: boolean;
  closed: boolean;
  language: string;
  creationTime: string;
  lastTimeViewed: string;
  subscriptionName: string;
  stripePromotionCode: string;
  stripeCustomPricePlanId: string;
  stripeTaxId: string;
  stripeMinimumPropertiesNumber: number;
  stripeNoIncrease2025: boolean;
  hasPaymentMethod: boolean;
  defaultMethodType: string;
  pushNotificationDeviceTokens: string[];
  lastPaidAmountMessage: Amount;
  lastPaidAmount: Amount;
  totalPendingAmount: Amount;
  trialPeriodOver: boolean;
  trialPeriodOverDate: string;
  onBoarding: OnBoarding;
  failedInvoices: string[];

  airbnbAccounts: AirbnbAccount[];
  bookingDotComAccounts: BookingDotComAccount[];
  avantioAccounts: AvantioAccount[];
  bookingSynchAccounts: BookingSyncAccount[];
  smoobuAccounts: SmoobuAccount[];
  beds24Accounts: Beds24Account[];
  eviivoAccounts: EviivoAccount[];
  rentalReadyAccounts: RentalReadyAccount[];
  giteDeFranceAccounts: GiteDeFranceAccount [];
  icneaAccounts: IcneaAccount[];
  hostifyAccounts: HostifyAccount[];
  superhoteAccounts: SuperhoteAccount[];
  guestyAccounts: GuestyAccount[];
  septeoAgencyIds: string[];
  yaagoTeamInfo: MobileAppInformation;
  mobileDeviceInfo: string;
  brevoEmailLaunchProcess: string;
  areaOfBusiness: string;
}

export class OtaAccount {
  id: string;
  otaTokenStatus: string;
  lastSyncDatetime: string;
  propertyOwnerPersonEmail: string;
  lastSyncElapseTime: string;
  creationDatetime: string;
  numberOfProperties: number;
  justUpdateExistingBooking: boolean;
  noPropertyCreation: boolean;
  challengeContextPhoneHash: string;
  challengeContext: string;
  challengeContextTime: string;
  corporateId: string;
  personEmail: string;

}
export class RentalReadyAccount extends OtaAccount {
  username: string;
  rentalReadyUserId: number;
  lastAccessRefresh: string;
  refreshToken: string;
  expires_in: number;
}
export class GiteDeFranceAccount extends OtaAccount  {
  giteDeFranceEmail: string;
  giteDeFrancePassword: string;
  giteDeFrancePropertyReferenceList: string[];
}
export class EviivoAccount extends OtaAccount {
  clientId: string;
  clientSecret: string;
  lastAccessRefresh: string;
}
export class SmoobuAccount extends OtaAccount {
  apiKey: string;
}
export class HostifyAccount extends OtaAccount {
  apiKey: string;

}
export class GuestyAccount extends OtaAccount {
  clientId: string;
  clientSecret: string;
}
export class SuperhoteAccount extends OtaAccount {
  email: string;
  password: string;
}
export  class IcneaAccount extends OtaAccount {
  ownerId: string;
}
export class BookingSyncAccount extends OtaAccount {
  businessName: string;
  email: string;
  lastSourceUpdateSince: string;
  lastClientUpdateSince: string;
  lastBedroomsUpdateSince: string;
  lastConversationUpdatedSince: string;
  lastMessageUpdatedSince: string;
  lastBookingFeeUpdatedSince: string;
  lastPhotoUpdatedSince: string;
  lastReviewsUpdatedSince: string;
  lastTagUpdatedSince: string;
  lastBookingTaxesUpdatedSince: string;
  lastRentalUpatedSince: string;
  lastBookingUpdatedSince: string;
  lastHostsUpdatedSince: string;

}
export class AvantioAccount extends OtaAccount {
  avantioEmail: string;
  avantioPassword: string;
  avantioRootUrl: string;
  avantioLayoutType: string;
  monthsToLoad: number;
  avantioCredentialUsername: string;
  avantioCredentialPassword: string;
  avantioProviderId: string;
  avantioNewApiAuthKey: string;
}
export class BookingDotComAccount extends OtaAccount {
  bookingDotComEmail: string;
  bookingDotComPassword: string;
  bookingDotComDeviceId: string;
  bookingIamAccessToken: string;
  refreshToken: string;
}
export class AirbnbAccount extends OtaAccount {
  airbnbEmail: string;
  airbnbPassword: string;
  airbnbDeviceId: string;
  fromServer: boolean;
  lastReconnectionDateTime: string;
}
export class Beds24Account extends OtaAccount {
  apiKey: string;

}

export class AdminStaffDetail {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  yaagoTeamTokens: boolean;
  lastTimeViewed: string;
  yaagoTeamInfo: MobileAppInformation;
  mobileDeviceInfo: string;
}

export class KeynestAccount {
  keyList: Keynest_Key[]
  lastRefreshDeviceTime: string;
}
export class Keynest_Key {
  KeyId: string;
  KeyName: string;
  StatusType: string;

}
