import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InfluencerDetails } from '../../model/PersonDto';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../interceptors/auth.interceptors";
import * as i3 from "./conf.service";
export class AdminService {
    constructor(http, authInterceptor, confService) {
        this.http = http;
        this.authInterceptor = authInterceptor;
        this.confService = confService;
    }
    static getHeaders() {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }
    static getOptionsWithParams(httpParams) {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    }
    static getHeadersForUpload() {
        return { headers: new HttpHeaders() };
    }
    formatErrors(error) {
        return throwError(error.error);
    }
    getTokenHeaders() {
        return { headers: new HttpHeaders({ Authorization: this.authInterceptor.getAdminKey() }) };
    }
    getBlobHeaders() {
        return {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json'),
            responseType: 'blob'
        };
    }
    getLogins() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getCorporateOwnerLogins() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate-owners', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getNumberOfProperties() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/property/count', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getNumberOfBookings() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/propertybooking/count', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    clone(personId) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/clone/' + personId, null, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    deleteLogin(personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    closeLogin(personId) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/close/' + personId, new InfluencerDetails(), this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    unCloseLogin(personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/close/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    pauseLogin(personId) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/pause/' + personId, new InfluencerDetails(), this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    addBtobInfo(personId, btobinfo) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/btob/' + personId, btobinfo, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    addInfluencer(personId, influencerDetail) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/influencer/' + personId, influencerDetail, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    removeInfluencer(personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/influencer/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    removeBtobInfo(personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/btob/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setAsBeta(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/beta/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getProperties(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/properties/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    removeBeta(personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/beta/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setPerformnce(personId) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/subscription/performance/' + personId, null, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setSuccess(personId) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/subscription/success/' + personId, null, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    deleteOtaAccount(type, otaEmail, personId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/admin/otaaccount/' + type + '/' + otaEmail + '/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    updateCorporate(corporate) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate', corporate, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    removeInvoices(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/billing/removeinvoice/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setPromotionCode(personIdAndPromotionCode) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/billing/promotion-code', personIdAndPromotionCode, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getPaidUsers() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/paid/customers', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getAmbassadors() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ambassador/get-all', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getDashboard() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard-tech', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getYaagoAcademy() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/yaago-academy/all', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    createOrUpdateYaagoAcademy(yaagoShort) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/admin/yaago-academy', yaagoShort, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    convertToCorporate(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/convert-to-corporate/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    exportBrevo() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/brevo/export-corporate-owner', this.getBlobHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setLaunchProcessTask(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/launch-process/TASKS/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setLaunchProcessXp(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/launch-process/XP/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setForceNoIncrease2025(personId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-noIncrease2025/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    setAreaOfBusiness(personId, area) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/areaofbusiness/' + area + '/' + personId, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getPmsPerf(startDate) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/pms-property-calculation/get-pms-property/' + startDate, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getUpSellStatistics() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/upsell-onboarding-statistics/get-all', this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getStripeCustomerTurnover(year) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/admin/turnover/get-turnover/' + year, this.getTokenHeaders())
            .pipe(catchError(this.formatErrors));
    }
    getImage() {
        return this.http.get('http://localhost:8080/api-v2/support/test-get-image-bucket-secured', this.getBlobHeaders())
            .pipe(catchError(this.formatErrors));
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthInterceptor), i0.ɵɵinject(i3.ConfService)); }, token: AdminService, providedIn: "root" });
export class PersonIdAndPromotionCode {
}
