import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {InfluencerDetails, PersonDto, PersonDtoOld} from '../../model/PersonDto';
import { AuthInterceptor } from '../interceptors/auth.interceptors';
import { ConfService } from './conf.service';
import {BtobInfo, Person} from "../../model/person";
import {Property} from "../../model/property";
import {Corporate} from "../../model/Corporate";
import {PaidUsers} from "../../model/PaidUsers";
import {YaagoAmbassador} from "../../model/YaagoAmbassador";
import {DashboardTechResponse} from "../../model/dashboard/DashboardTechResponse";
import {YaagoAcademyShort} from "../../model/academy/YaagoAcademyShort";
import {PmsPropertyActive} from "../../model/pmsPropertyActive/PmsPropertyActive";
import {UpSellOnboardingStatistics} from "../../model/V4_Statistics/UpSellOnboardingStatistics";
import {StripeCustomerTurnover} from "../../model/V4_Stripe_Turnover/StripeCustomerTurnover";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient,
              private authInterceptor: AuthInterceptor,
              private confService: ConfService) {
  }

  static getHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({'Content-Type': 'application/json'}, )};
  }

  static getOptionsWithParams(httpParams: HttpParams) {
    return {headers: new HttpHeaders({'Content-Type': 'application/json'}), params: httpParams};
  }

  static getHeadersForUpload(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders()};
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getTokenHeaders(): { headers: HttpHeaders } {
    return {headers: new HttpHeaders({Authorization: this.authInterceptor.getAdminKey()}, )};
  }

  getBlobHeaders(): { headers?: HttpHeaders, responseType } {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
      , responseType: 'blob' as 'json'
    };
  }

  public getLogins(): Observable<PersonDtoOld[]> {
    return this.http.get<PersonDtoOld[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getCorporateOwnerLogins(): Observable<PersonDto[]> {
    return this.http.get<PersonDto[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate-owners', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }


  public getNumberOfProperties(): Observable<BigInteger> {
    return this.http.get<BigInteger>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/property/count', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getNumberOfBookings(): Observable<BigInteger> {
    return this.http.get<BigInteger>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/propertybooking/count', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }


  public clone(personId: string): Observable<PersonDtoOld> {
    return this.http.put<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/clone/' + personId, null, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public deleteLogin(personId: string): Observable<void> {
    return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public closeLogin(personId: string): Observable<void> {
    return this.http.put<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/close/' + personId, new InfluencerDetails(), this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public unCloseLogin(personId: string): Observable<void> {
    return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/close/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public pauseLogin(personId: string): Observable<void> {
    return this.http.put<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/pause/' + personId, new InfluencerDetails(), this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public addBtobInfo(personId: string, btobinfo: BtobInfo): Observable<PersonDtoOld> {
    return this.http.put<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/btob/' + personId, btobinfo, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public addInfluencer(personId: string, influencerDetail: InfluencerDetails): Observable<PersonDto> {
    return this.http.put<PersonDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/influencer/' + personId, influencerDetail, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public removeInfluencer(personId: string): Observable<PersonDto> {
    return this.http.delete<PersonDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/influencer/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public removeBtobInfo(personId: string): Observable<PersonDtoOld> {
    return this.http.delete<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/btob/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setAsBeta(personId: string): Observable<PersonDtoOld> {
    return this.http.get<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/beta/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getProperties(personId: string): Observable<Property[]> {
    return this.http.get<Property[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/properties/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public removeBeta(personId: string): Observable<PersonDtoOld> {
    return this.http.delete<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/beta/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setPerformnce(personId: string): Observable<PersonDtoOld> {
    return this.http.post<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/subscription/performance/' + personId, null, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setSuccess(personId: string): Observable<PersonDtoOld> {
    return this.http.post<PersonDtoOld>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/subscription/success/' + personId, null, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public deleteOtaAccount(type: string, otaEmail: string, personId: string) {
    return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/otaaccount/' + type + '/' + otaEmail + '/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public updateCorporate(corporate: Corporate) {
    return this.http.put<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/corporate', corporate,  this.getTokenHeaders())
  .pipe(catchError(this.formatErrors));
  }

  public removeInvoices(personId: string): Observable<PersonDto> {
    return this.http.get<PersonDto>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/billing/removeinvoice/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }



  public setPromotionCode(personIdAndPromotionCode: PersonIdAndPromotionCode): Observable<Person> {

    return this.http.post<Person>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/billing/promotion-code', personIdAndPromotionCode, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getPaidUsers(): Observable<PaidUsers> {
    return this.http.get<PaidUsers>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/paid/customers', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getAmbassadors(): Observable<YaagoAmbassador[]> {
    return this.http.get<YaagoAmbassador[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/ambassador/get-all', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getDashboard(): Observable<DashboardTechResponse> {
    return this.http.get<DashboardTechResponse>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/dashboard-tech', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getYaagoAcademy(): Observable<YaagoAcademyShort[]> {
    return this.http.get<YaagoAcademyShort[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/yaago-academy/all', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public createOrUpdateYaagoAcademy(yaagoShort: YaagoAcademyShort): Observable<void> {
    return this.http.post<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/yaago-academy', yaagoShort, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public convertToCorporate(personId: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/convert-to-corporate/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  exportBrevo() : Observable<any> {
    return this.http.get<any>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/brevo/export-corporate-owner', this.getBlobHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setLaunchProcessTask(personId: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/launch-process/TASKS/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }
  public setLaunchProcessXp(personId: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/launch-process/XP/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setForceNoIncrease2025(personId: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/force-noIncrease2025/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public setAreaOfBusiness(personId: string, area: string): Observable<void> {
    return this.http.get<void>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/person/areaofbusiness/' + area + '/' + personId, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getPmsPerf(startDate: string): Observable<PmsPropertyActive[]> {
    return this.http.get<PmsPropertyActive[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/pms-property-calculation/get-pms-property/' + startDate, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getUpSellStatistics(): Observable<UpSellOnboardingStatistics[]> {
    return this.http.get<UpSellOnboardingStatistics[]>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/upsell-onboarding-statistics/get-all', this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getStripeCustomerTurnover(year: string) : Observable<StripeCustomerTurnover> {
    return this.http.get<StripeCustomerTurnover>(this.confService.getBackendApiEndPoint() + 'api-v2/admin/turnover/get-turnover/' + year, this.getTokenHeaders())
      .pipe(catchError(this.formatErrors));
  }

  public getImage(): Observable<any> {
    return this.http.get<any>('http://localhost:8080/api-v2/support/test-get-image-bucket-secured', this.getBlobHeaders())
      .pipe(catchError(this.formatErrors));
  }

}
export class PersonIdAndPromotionCode {
  personId: string;
  promotionCode: string;
}
